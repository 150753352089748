import React, { useState } from "react";
import Typewriter from "typewriter-effect";

function Type() {
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  return (
    <>
      {!isTypingComplete ? (
        <Typewriter
          options={{
            strings: [
              "software engineer",
              "writer",
              "aspiring film-maker",
              "casual art enthusiast"
            ],
            autoStart: true,
            loop: false, // Set loop to false
            deleteSpeed: 30,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("software engineer")
              .pauseFor(2000)
              .deleteAll()
              .typeString("writer")
              .pauseFor(2000)
              .deleteAll()
              .typeString("aspiring film-maker")
              .pauseFor(2000)
              .deleteAll()
              .typeString("casual art enthusiast")
              .pauseFor(1000)
              .callFunction(() => {
                setIsTypingComplete(true);
              })
              .start();
          }}
        />
      ) : (
        <p className="Typewriter__wrapper">
          software engineer<br></br>
          writer<br></br>
          aspiring film-maker<br></br>
          casual art enthusiast<br></br>
        </p>
      )}
    </>
  );
}

export default Type;
