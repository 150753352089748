import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Footer from "./components/Footer";
import OTProfiles from "./pages/Projects/OT-Profiles/OTProfiles";
import AmazonProject from "./pages/Projects/Amazon/AmazonProject";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import './index.css';
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Art from "./pages/Radiohead/Art/Art";
import Profile from "./pages/Profile/Profile";
import Projects from "./pages/Projects/Projects";
import SoftwareEngineer from "./pages/BecomingMiscellaneous/SoftwareEngineer/SoftwareEngineer";
import Wave from "./pages/BecomingMiscellaneous/Wave/Wave";
import Contact from "./pages/Contact/Contact";
import Books from "./pages/Radiohead/Books/Books";
import Photography from "./pages/Paraphrenalia/Photography/Photography";
import YEAR_2024 from "./pages/Time/2024";
import YEAR_2023 from "./pages/Time/2023";
import YEAR_2011 from "./pages/Time/2011";
import YEAR_2010 from "./pages/Time/2010";
import YEAR_2008 from "./pages/Time/2008";
import Education from "./pages/Radiohead/Education/Education";
import Theater from "./pages/Paraphrenalia/Photography/Theater";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path="*" element={<Navigate to="/"/>} />
          
          {/* Becoming Miscellaneous */}
          <Route path="/softwareengineer" element={<SoftwareEngineer />} />
          <Route path="/softwareengineer/wave" element={<Wave/>} />
          <Route path="/artist" element={<Art/>}/>

          {/* Radiohead */}
          <Route path="/radiohead/books" element={<Books/>}/>
          <Route path="/radiohead/education" element={<Education/>}/>

          {/* Paraphrenalia */}
          <Route path="/paraphrenalia/photography" element={<Photography/>}/>
          <Route path="/paraphrenalia/theater" element={<Theater/>}/>
          {/* Keeping Time */}
          <Route path="/time/2024" element={<YEAR_2024/>}/>
          <Route path="/time/2023" element={<YEAR_2023/>}/>
          <Route path="/time/2011" element={<YEAR_2011/>}/>
          <Route path="/time/2010" element={<YEAR_2010/>}/>
          <Route path="/time/2008" element={<YEAR_2008/>}/>
          {/* <Route path="/ot-profiles" element={<OTProfiles/>} />
          <Route path="/oasis-sil-naws-migration" element={<AmazonProject/>} /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
