import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';


function Books() {
  const [hoveredText, setHoveredText] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleMouseEnter = (text) => {
    setHoveredText(text);
  };

  const handleMouseLeave = () => {
    setHoveredText(null);
  };

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  const content = {
    padding: '50px 0 0 0',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'left',
    width: 250,
    height: 250,
    // fontSize: 'large'
  }

  const tooltipStyle = {
    position: 'absolute',
    top: cursorPos.y + 20, // 20px below the cursor
    left: cursorPos.x + 20, // 20px to the right of the cursor
    backgroundColor: 'white',
    padding: '5px',
    border: '1px solid black',
    zIndex: 1000,
    pointerEvents: 'none'
  };

  return (
    <section onMouseMove={handleMouseMove}>
      <div className="grid-background"></div> 
      <Container fluid className="art-section">
        <div className="grid-container">
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
        </div>
        {hoveredText && (
          <div style={tooltipStyle}>
            {hoveredText}
          </div>
        )}
      </Container>
    </section>
  );
}

export default Books;
