import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Import paper backgrounds
import paper1 from '../assets/postcard/paper/paper1.png';
import paper2 from '../assets/postcard/paper/paper2.png';
import paper3 from '../assets/postcard/paper/paper3.png';
import paper4 from '../assets/postcard/paper/paper18.png';
import paper5 from '../assets/postcard/paper/paper5.png';
import paper6 from '../assets/postcard/paper/paper12.png';
import paper from "../assets/postcard/paper/paper.jpg";

// Import images for the left column
import image1 from '../assets/postcard/image/photo1.jpg';
import image2 from '../assets/postcard/image/photo2.jpg';
import image3 from '../assets/postcard/image/photo3.jpg';
import image8 from '../assets/postcard/image/photo8.jpg';

// Styled-components for styling the postcard
const PostcardContainer = styled.div`
  width: 600px;
  height: 400px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  position: relative;
  overflow: fill;
  border-radius: 2px;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  padding: 30px;
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 1rem 2rem 4rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: small;
  text-align: left;
  z-index: 2;
  position: relative;
  font-family: 1942;
  p {
    color: #50557A; /* Ensure the text inside the paragraph is dark blue */
  }
`;

const PostcardImage = styled.img`
  width: 600px;
  max-height: 100%;
  padding: 30px;
  overflow: crop;
  opacity: 0.2;
`;

const Postcard = () => {
  const [paperBackground, setPaperBackground] = useState('');
  const [bgpaperBackground, setBgPaperBackground] = useState('');
  const [leftImage, setLeftImage] = useState('');

  useEffect(() => {
    // Arrays of images
    const paperImages = [paper1, paper2];
    const bgPaper = [paper4, paper5, paper6]
    const leftImages = [image1, image2, image8];

    // Function to get a random image from an array
    const getRandomImage = (images) => {
      return images[Math.floor(Math.random() * images.length)];
    };

    setPaperBackground(getRandomImage(paperImages));
    setBgPaperBackground(getRandomImage(bgPaper));
    setLeftImage(getRandomImage(leftImages));
  }, []);

  function getTodaysDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  function getRandomQuote() {
    const quotes = [
      // T.S. Eliot Quotes
      "Only those who will risk going too far can possibly find out how far one can go. - T.S. Eliot",
      "For last year's words belong to last year's language. And next year's words await another voice. - T.S. Eliot",
      "The most important thing for poets to do is to write as little as possible. - T.S. Eliot",
      
      // Shakespeare Quotes
      "To be, or not to be, that is the question. - William Shakespeare",
      "All the world's a stage, and all the men and women merely players. - William Shakespeare",
      "Some are born great, some achieve greatness, and some have greatness thrust upon them. - William Shakespeare",
      
      // Kafka Quotes
      "A book must be the axe for the frozen sea within us. - Franz Kafka",
      "I am a cage, in search of a bird. - Franz Kafka",
      "The meaning of life is that it stops. - Franz Kafka",
      
      // George Eliot Quotes
      "It is never too late to be what you might have been. - George Eliot",
      "What do we live for, if it is not to make life less difficult for each other? - George Eliot",
      "Our deeds determine us, as much as we determine our deeds. - George Eliot"
    ];
  
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  }

  return (
    <>
    <PostcardContainer style={{ backgroundImage: `url(${leftImage})` }}>
    </PostcardContainer>
    <PostcardContainer style={{ backgroundImage: `url(${paperBackground})` }}>
        
      <LeftColumn>
        
      </LeftColumn>
      <RightColumn>
        <p>
        {getTodaysDate()}<br></br>
        {getRandomQuote()}
        </p>
      </RightColumn>
      
    </PostcardContainer>
    </>
    
  );
};

export default Postcard;
