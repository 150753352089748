import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import bg_1 from "../../assets/bg_1.png";
import AudioPlayer from "../../components/AudioPlayer";

import guitar from "../../assets/audio/guitar.mp3";
import GuestBook from "./Guestbook";

function About() {
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const intro_blurb = {
    textAlign: 'justify',
    position: 'absolute',
    top: '40vh',
    left: isMobile ? '60vw' : '80vw',
    width: isMobile ? '20vw' : '15vw',
    fontSize: 'large',
  };

  const content = {
    padding: '7rem 4rem 0rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '50px',
    textAlign: 'left',
    fontSize: '22px',
    gap: '0px',
    fontFamily: 'MyFont',
    fontWeight: 800,
  }
  
  const img_style = {
    maxWidth: '60vw'
  }

  return (
    <section>
      <Container fluid className="home-section" >
        <Container style={content}>
        <img src={bg_1} style={img_style}></img>
          
          <AudioPlayer src={guitar}/>
        </Container>
        <div style={content}>
            <p>my name is sky jung. i was born in los angeles, ca 
              and grew up between seoul, korea and orange 
              county, ca (where i presently reside). i study 
              computer science and art, film and visual studies 
              at harvard, and i enjoy finding ways to combine 
              my passion for engineering and art! in my free 
              time, i like to read, write, play my electric guitar,
               watch documentaries, and explore new cafes. 
            </p>
            <div>
            <iframe src="https://www3.cbox.ws/box/?boxid=3539128&boxtag=vyFQoV" width="100%" height="300"  allow="autoplay" frameborder="0" marginheight="0" marginwidth="0" scrolling="auto"></iframe>	
            {/* <a href="http://users3.smartgb.com/g/g.php?a=s&i=g36-36644-03"><img alt="Guestbook" border="0" src="http://extras3.smartgb.com/b/gb_80x40.gif" width="80" height="40"/></a> */}
            </div>
            
          </div>
      </Container>
      
      
    </section>
  );
}

export default About;
