import { fontWeight } from "@mui/system";
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

function YEAR_2010() {
  const text = {
    padding: "auto",
    margin: "auto",
    textAlign: "center",
    paddingBottom: 20,
    fontFamily: 'Typewriter',
  }
  return (
    <section>
        <Container className="home-section" id="2010">
          <div style={text}>
            2010
          </div>   
        </Container>
    </section>
    
  );
}

export default YEAR_2010;
