import { fontWeight } from "@mui/system";
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

function Contact() {
  const text = {
    padding: "auto",
    margin: "auto",
    textAlign: "center",
    paddingBottom: 20,
  }
  return (
    <section>
        <Container className="home-section" id="contact">
            <div style={text}>
            <span id="phonein">skyjung@college.harvard.edu</span><br></br>
            </div>            
        </Container>
    </section>
    
  );
}

export default Contact;
