import { fontWeight } from "@mui/system";
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import StopMotionGallery from "../../components/StopMotionGallery";
import Postcard from "../../components/Postcard";

function YEAR_2024() {
  const text = {
    padding: "auto",
    margin: "auto",
    textAlign: "center",
    paddingBottom: 20,
    fontFamily: 'Typewriter',
  }
  const container = {
    padding: '8rem 0 0 0'
  }
  return (
    <section>
        <Container className="home-section" id="2024" style={container}>
          <div style={text}>
            2024
            <Postcard/>
          </div>
        </Container>
    </section>
    
  );
}

export default YEAR_2024;
