// src/components/AudioPlayer.js

import React, { useRef, useState, useEffect } from 'react';
import pauseIcon from "../assets/audio/pause.svg";
import playIcon from "../assets/audio/play-one.svg";
import volumeDownIcon from "../assets/audio/volume-down.svg";
import volumeUpIcon from "../assets/audio/volume-up.svg";
import muteIcon from "../assets/audio/volume-mute.svg";

const AudioPlayer = ({ src }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const stopAudio = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    const toggleMute = () => {
        audioRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const increaseVolume = () => {
        let newVolume = Math.min(volume + 0.1, 1);
        audioRef.current.volume = newVolume;
        setVolume(newVolume);
    };

    const decreaseVolume = () => {
        let newVolume = Math.max(volume - 0.1, 0);
        audioRef.current.volume = newVolume;
        setVolume(newVolume);
    };

    const handleTimeUpdate = () => {
        const current = audioRef.current.currentTime;
        const progress = (current / audioRef.current.duration) * 100;
        setProgress(progress);
        setCurrentTime(current);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const handleProgressClick = (e) => {
        const progressContainer = e.target;
        const rect = progressContainer.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const newTime = (offsetX / progressContainer.offsetWidth) * audioRef.current.duration;
        audioRef.current.currentTime = newTime;
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        return `${hours > 0 ? `${hours}:` : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <audio ref={audioRef} src={src} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata}></audio>
            <div id="video-controls" className="controls" data-state="hidden">
                <button id="playpause" type="button" onClick={togglePlayPause}>
                    <img src={isPlaying ? pauseIcon : playIcon} alt="Play/Pause" />
                </button>
                <span className='duration_text'>{formatTime(currentTime)}</span> 
                <div className="progress" onClick={handleProgressClick}>
                    <progress id="progress" value={progress} min="0" max="100">
                        <span id="progress-bar" style={{ width: `${progress}%` }}></span>
                    </progress>
                </div>
                <span className='duration_text'>{formatTime(duration)}</span>
                <button id="mute" type="button" onClick={toggleMute}>
                    <img src={isMuted ? volumeDownIcon : muteIcon} alt="Mute/Unmute" />
                </button>
                {/* <button id="volinc" type="button" onClick={increaseVolume}>
                    <img src={volumeUpIcon} alt="Volume Up" />
                </button>
                <button id="voldec" type="button" onClick={decreaseVolume}>
                    <img src={volumeDownIcon} alt="Volume Down" />
                </button> */}
            </div>
        </div>
    );
}

export default AudioPlayer;
