import { fontWeight } from "@mui/system";
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import StopMotionGallery from "../../../components/StopMotionGallery";

function Photography() {
  const [key, setKey] = useState("images");
  const [count, setCount] = useState(7);
  const [square, setSquare] = useState(false);
  const text = {
    padding: "auto",
    margin: "auto",
    textAlign: "center",
    paddingBottom: 20,
  }

  const table_of_contents = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "left",
    padding: '0 0 0 8rem',
    justifyContent: 'center',
    width: '50vw',
  }

  const flex_col = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "right",
    padding: '8rem 0 2rem 0',
    width: '75vw',
  }



  const link_style = {
    marginBottom: 0,
    cursor: 'pointer',
  }

  function handleClick (key: string, count: Number, square?: boolean) {
    setKey(key);
    setCount(count);
    if(square){
      setSquare(square);
    }
    
  }

  return (
    <section>
        <Container className="home-section" id="contact">
            <div style={table_of_contents}>
              <div>
                <p style={link_style} onClick={() => handleClick('film', 16)}>film</p>
                <p style={link_style} onClick={() => handleClick('seoul', 12)}>[230819] seoul</p>
                <p style={link_style} onClick={() => handleClick('ploy', 12, true)}>ploy</p>
                <p style={link_style} onClick={() => handleClick('images', 7)}>images</p>
              </div>
            
            </div>
            <div style={flex_col}>
            <StopMotionGallery path={key} count={count} square={square}></StopMotionGallery>            
            </div>
            
        </Container>
    </section>
    
  );
}

export default Photography;
