import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';


function Theater() {
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const intro_blurb = {
    textAlign: 'justify',
    position: 'absolute',
    top: '40vh',
    left: isMobile ? '60vw' : '80vw',
    width: isMobile ? '20vw' : '15vw',
    fontSize: 'large',
  };

  const content = {
    padding: '8rem 4rem 4rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: '45px',
    gap: '0px',
    fontFamily: 'MyFont',
    fontWeight: 800,
  }
  
  const img_style = {
    maxWidth: '60vw'
  }

  return (
    <section>
      <Container fluid className="home-section" >
        <Container style={content}>
            <h3>Harvard Radcliffe Drama Center</h3>
            <a href="https://harvardindependent.com/heathers-the-musical-takes-center-stage-at-harvard/">Heathers: The Musical - Props Designer</a>
            <a href="https://my.hrdctheater.org/archive/view/1968/">Spring Awakening - Props Designer</a>
            <a href="https://my.hrdctheater.org/archive/view/1970/">Little Shop of Horrors - Props Designer</a>
            <a href="https://hrdctheater.org/shows/romeo-juliet/">Romeo & Juliet - Tech Producer</a>
            <a style={{"fontSize":"40px"}}>click for more^!</a>
        </Container>
      </Container>
      
      
    </section>
  );
}

export default Theater;
