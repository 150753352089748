import React from 'react';
import {Container, Col} from 'react-bootstrap';


function Wave() {
    const demo_style = {
        // padding:'auto',
        margin:'auto',
    }
    return(
        <section>
            <Container className="home-section">
            <div style={demo_style}>
                <iframe style={{"border": "1px solid rgba(0, 0, 0, 0.1)","width":"390px","height":"892px"}} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FIvBNh7ZabrbL5wRq1xTutb%2Fwave-design%3Fpage-id%3D500%253A106%26node-id%3D592-703%26viewport%3D508%252C407%252C0.13%26t%3DQaAQw4Ww1Y1EwMbC-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D501%253A430" allowfullscreen></iframe>
                <br></br>
                <p >demo</p>
                <a href="/softwareengineer">go back</a>
            </div>
            
            </Container>
        </section>
    );
}

export default Wave