import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css';
import styled from 'styled-components';
import folder from "../../../assets/folder.png";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import pdf from './resume.pdf'
import afvs_24 from "./syllbus/afvs_24.pdf";
import gened_1179 from "./syllbus/gened_1179.pdf";
import sociol_1181 from "./syllbus/sociol_1181.pdf";
import english_115an from "./syllbus/english_115an.pdf";
import complit_278 from "./syllbus/complit_278.pdf";
import cs_121 from "./syllbus/cs_121.pdf";
import cs_107 from "./syllbus/cs_107.pdf";
import gened_1011 from "./syllbus/gened_1011.pdf";

const container = {
    padding: '8rem 0 0 3rem',
    textAlign: 'left',
    fontFamily: 'Deja Vu',
};

const Sidebar = styled.div`
  width: 25%;
  overflow-y: auto;
  padding: 20px;
  font-size: 14px;
`;

const Content = styled.div`
  width: 60%;
  height: 600px;
  padding: 3rem;
  border: 1px solid;
  border-radius: 5px;
`;

const Folder = styled.div`
  margin-bottom: 10px;
`;

const FolderLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin-top: 3px;
`;

const FolderIcon = styled.img`
  margin-right: 10px;
  width: 15px;
  height: 15px;
`;

const File = styled.div`
  margin-left: 20px;
  cursor: pointer;
  margin-top: 5px;
  
`;

const pdf_preview = {
  'height': '500px',
  'overflowY': 'scroll',
  'overflowX': 'hidden',     // Hides horizontal scroll
  'width': '100%', 
}

const Education = () => {
    const [selectedContent, setSelectedContent] = useState(null);
    const [collapsed, setCollapsed] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleLinkClick = (content) => {
        setSelectedContent(content);
    };

    const toggleCollapse = (label) => {
        setCollapsed((prevCollapsed) => ({
          ...prevCollapsed,
          [label]: !prevCollapsed[label],
        }));
      };

      const directoryStructure = [
        {
          label: 'harvard',
          children: [
            {
              label: 'spring_24',
              children: [
                { 
                  label: 'afvs_24', 
                  children: [
                    { 
                      label: 'afvs_24', 
                      content: (
                        <div>
                          <h1>Painting, Smoking, Eating</h1>
                          <p>Professor: Matt Saunders</p>
                          <p style={{fontSize: '14px'}}> Titled after Philip Guston, this course has two agendas: technical assignments that improve your ability to move paint around and developing groundwork for personal projects. From the space of the studio an artist reflects on the world. We will consider the role of artists in society and the boundaries between interior and exterior meaning, as we develop practices that are both self-expressive and self-reflective, with special curiosity about auteurs, flaneurs, and all painters with individual and committed voices.
                          </p>
                        </div>
                      ) 
                    },
                    { 
                      label: 'syllabus', 
                      content: (
                        <div style={pdf_preview}>
                          <Document file={afvs_24} onLoadError={console.error}>
                            <Page pageNumber={1} />
                            <Page pageNumber={2} />
                            <Page pageNumber={3} />
                            <Page pageNumber={5} />
                          </Document>
                        </div>
                        
                      )
                    },
                  ]
                },
                { 
                  label: 'english_151an', 
                  children: [
                    { 
                      label: 'english_151an', 
                      content: (
                        <div>
                          <h2>ENGLISH 151AN: The Age of the Novel</h2>
                          <p>Professor: Tara K. Menon </p>
                          <p style={{fontSize: '14px'}}> What does the novel still have to offer? As newer genres—movies, television, Youtube, TikTok—compete for our attention, why do people still immerse themselves in long works of prose fiction? And why do certain nineteenth-century British novels continue to captivate so many readers to this day? In this course, we will read four nineteenth-century novels by four authors that many consider to be the greatest writers that have ever lived: Jane Austen’s Pride and Prejudice, Mary Shelley’s Frankenstein, Charles Dickens’s Little Dorrit, and George Eliot’s Middlemarch. We will pay close attention to technique: how do these novels work? And we will also explore social and political themes: what are these novels about? At every stage, we will consider the unique capacities of narrative fiction: what can the novel do that other genres can’t? Implicitly and explicitly, this course will argue first, that these superlative nineteenth-century novels let us see the world (not only then but also now) in new ways, and second, that the novel is a tool for thinking that beats all others. Alongside these texts, we will watch film, television and theatre adaptations as well as read contemporary criticism to better understand the enduring legacy of these canonical works.</p>
                        </div>
                      ) 
                    },
                    { 
                      label: 'syllabus', 
                      content: (
                        <div style={pdf_preview}>
                          <Document file={english_115an} onLoadError={console.error}>
                            {Array.from({ length: 13 }, (_, k) => (
                              <Page key={k} pageNumber={k + 1} />
                            ))}
                          </Document>
                        </div>
                      )
                    },
                  ]
                },
                { 
                  label: 'sociol_1181', 
                  children: [
                    { 
                      label: 'sociol_1181', 
                      content: (
                        <div>
                          <h2>SOCIOL 1181: Social Change in Modern Korea</h2>
                          <p>Professor: Paul Chang </p>
                          <p style={{fontSize: '14px'}}> This course explores the incredible transformation of Korean society in the modern period. We begin with the demise of the Chosŏn Dynasty at the end of the 19th century before discussing the Japanese colonial period (1910-1945), the emergence of two Korean nation-states (1945-1948), the Korean War (1950-53), and the contemporary period (1960-present). The course is divided into two sections: in the first section we will discuss Korea’s political and economic transformation, and in the second part we will cover social and cultural change. Upon completion of the course students should have a thorough grasp of Korea’s modernization trajectory in the 20th and 21st centuries.</p>
                        </div>
                      ) 
                    },
                    { 
                      label: 'syllabus', 
                      content: (
                        <div style={pdf_preview}>
                          <Document file={sociol_1181} onLoadError={console.error}>
                          {Array.from({ length: 6 }, (_, k) => (
                            <Page key={k} pageNumber={k + 1} />
                          ))}
                        </Document>
                        </div>
                      )
                    },
                  ]
                },
                { 
                  label: 'gened_1179', 
                  children: [
                    { 
                      label: 'gened_1179', 
                      content: (
                        <div>
                          <h2>GENED 1179: Psychotherapy and the Modern Self</h2>
                          <p>Professor: Elizabeth Lunbeck</p>
                          <p style={{fontSize: '14px'}}> The demand for therapy is currently at an all-time high, bolstering its century-long dominance as the preferred antidote to human unhappiness and misery, even as it is under sustained attack from critics characterizing it as self-indulgent as well as from platforms that would replace human therapists with chatbots and analysts with algorithms. This course explores the conflicts and controversies that characterize today’s psychotherapeutic landscape, addressing questions concerning its present condition and future prospects. We will look at the development, methods, aims, efficacy, and limitations of a range of psychotherapeutic modalities—among them psychoanalytic, psychodynamic, cognitive, behavioral, manualized, evidence-based, and AI-informed treatments as well as family, sex, and group therapies—and explore how each took shape, who it is intended to treat, and how clinicians evaluate its effectiveness. We will examine therapy’s long-overdue, on-going reckoning with racial issues, gendered identities, and access to treatment. We will explore the various modern selves envisioned by psychotherapy, from the highly relational to the independently sovereign. You will leave the course prepared to recognize and evaluate claims regarding therapy’s rationale and impact in a range of sites, from the clinician’s office to the modern workplace to the media, as well as to assess the ways in which happiness, contentment, and satisfaction in life are subject—or not—to therapeutic intervention. Does psychotherapy work, and, if so, how? Do we suffer less and enjoy greater self-knowledge one hundred years after the invention of the talking cures?</p>
                        </div>
                      ) 
                    },
                    { 
                      label: 'syllabus', 
                      content: (
                        <div style={pdf_preview}>
                          <Document file={gened_1179} onLoadError={console.error}>
                          {Array.from({ length: 9 }, (_, k) => (
                            <Page key={k} pageNumber={k + 1} />
                          ))}
                          </Document>
                        </div>
                      )
                    },
                  ]
                },
              ],
            },
            {
              label: 'fall_23',
              children: [
                  { 
                    label: 'complit_278', 
                    children: [
                      { 
                        label: 'complit_278', 
                        content: (
                          <div>
                            <h2>COMPLIT 278: Hyperreality</h2>
                            <p>Professor: Panagiotis Roilos</p>
                            <p style={{fontSize: '14px'}}> The crisis of representation in postmodernity—closely connected with social and existential alienation and technological development—often manifests itself in terms of
                            “hyperreality,” where any distinction between “the real” and “the simulacrum” is blurred. The boundaries between “reality” and “non-reality” and relevant concepts (e.g. originality, authenticity, mimesis, simulacrum) have been explored and challenged from different but comparable perspectives in philosophy, art, and literature since classical antiquity. This seminar will investigate discourses on, or inspired by “hyperreality” and its epistemological, ontological, and political implications, from antiquity to postmodernity. Authors and thinkers to be discussed include Plato, Descartes, Schopenhauer, Jean Baudrillard, Guy Debord, Gilles Deleuze, Félix Guattari, Umberto Eco, Fredric Jameson, Paul Virilio, Bruno Latour, Elizabeth Grosz, Niklas Bostrom, Lucian, Pedro Calderón de la Barca, Franz Kafka, Jorge Luis Borges, William Gibson, Philip K. Dick, Christine Broke-Rose, Italo Calvino, Don DeLillo, Julian Barnes.</p>
                          </div>
                        ) 
                    },
                    { 
                      label: 'syllabus', 
                      content: (
                        <div style={pdf_preview}>
                          <Document file={complit_278} onLoadError={console.error}>
                          {Array.from({ length: 4 }, (_, k) => (
                            <Page key={k} pageNumber={k + 1} />
                          ))}
                          </Document>
                        </div>
                      )
                    },
                  ]
                },
                { 
                  label: 'cs_121', 
                  children: [
                    { 
                      label: 'cs_121', 
                      content: (
                        <div>
                          <h2>COMPSCI 121: Introduction to Theoretical Computer Science</h2>
                          <p>Professor: Boaz Barak</p>
                          <p style={{fontSize: '14px'}}> Computation occurs over a variety of substrates including silicon, neurons, DNA, the stock market, bee colonies and many others. In this course we will study the fundamental capabilities and limitations of computation, including the phenomenon of universality and the duality of code and data. Some of the questions we will touch upon include: Are there functions that cannot be computed? Are there true mathematical statements that can’t be proven? Are there encryption schemes that can’t be broken? Is randomness ever useful for computing? Can we use the quirks of quantum mechanics to speed up computation?</p>
                        </div>
                      ) 
                  },
                  { 
                    label: 'syllabus', 
                    content: (
                      <div style={pdf_preview}>
                        <Document file={cs_121} onLoadError={console.error}>
                        {Array.from({ length: 11 }, (_, k) => (
                          <Page key={k} pageNumber={k + 1} />
                        ))}
                        </Document>
                      </div>
                    )
                  },
                ]
              },
              { 
                label: 'cs_107', 
                children: [
                  { 
                    label: 'cs_107', 
                    content: (
                      <div>
                        <h2>COMPSCI 107: Systems Development for Computational Science</h2>
                        <p>Professor: Ignacio Becker Troncoso</p>
                        <p style={{fontSize: '14px'}}> This is a project-based course emphasizing designing, building, testing, maintaining and modifying software for scientific computing and data sciences. The class is focusing on a thorough introduction of the Python programming language with discussion of core concepts in object oriented programming as well as essential data structures useful in most programming tasks. Students will work in groups on a semester long project that combines the subjects discussed in class and applies them to the topic of automatic differentiation. Students will further learn how to work with SQL databases and how to integrate them in Python using SQLite3 and Pandas. After completion of this course, students will be able to adapt basic tools and techniques to design complex software systems aimed at solving computational and data processing problems in academic and industrial environments.</p>
                      </div>
                    ) 
                },
                { 
                  label: 'syllabus', 
                  content: (
                    <div style={pdf_preview}>
                      <Document file={cs_107} onLoadError={console.error}>
                      {Array.from({ length: 5 }, (_, k) => (
                        <Page key={k} pageNumber={k + 1} />
                      ))}
                      </Document>
                    </div>
                  )
                },
              ]
            },
            { 
              label: 'gened_1011', 
              children: [
                { 
                  label: 'gened_1011', 
                  content: (
                    <div>
                      <h2>GENED 1011: Contemporary Developing Countries: Entrepreneurial Solutions to Intractable Problems</h2>
                      <p>Professor: Tarun Khanna, Satchit Balsari</p>
                      <p style={{fontSize: '14px'}}> What problems do developing countries face, and how can individuals contribute to solutions
                        rather than awaiting the largesse of the state or other actors? Intractable problems – such as lack
                        of access to education and healthcare, deep-seated corruption, climate vulnerability, and
                        increased exposure to natural disasters – are part of the quotidian existence of the vast majority
                        of five of the world’s seven billion people. Developing societies suffer from what we refer to as
                        “institutional voids” that make organized activities of all sorts difficult; think of the mundane but
                        important physical infrastructure that allows us to get to work or school in the developed world,
                        as well as our access to higher-order institutions such as the availability of information at our
                        fingertips or the security of the rule of law. This course demonstrates that reflecting upon the
                        nature of the developing world’s intractable problems through different lenses helps characterize
                        candidate interventions to address them: the scientist’s hypothesis-driven and iterative
                        experimentation, the artist’s imagined counterfactuals through putting oneself in others’ shoes
                        literally and theatrically, and the planner’s top-down articulation of boundary conditions.</p>
                    </div>
                  ) 
              },
              { 
                label: 'syllabus', 
                content: (
                  <div style={pdf_preview}>
                    <Document file={gened_1011} onLoadError={console.error}>
                    {Array.from({ length: 49 }, (_, k) => (
                      <Page key={k} pageNumber={k + 1} />
                    ))}
                    </Document>
                  </div>
                )
              },
            ]
          },
              ],
            },
            // Other semesters go here...
          ],
        },
        {
          label: 'troy',
          children: [
            {
              label: 'classes',
              children: [
                { 
                  label: 'math', 
                  content: (
                    <div>
                      <h1>Math Classes</h1>
                      <p> Information about Math classes at Troy.</p>
                    </div>
                  )
                },
                { 
                  label: 'science', 
                  content: (
                    <div>
                      <h1>Science Classes</h1>
                      <p> Information about Science classes at Troy.</p>
                    </div>
                  )
                },
              ],
            },
            {
              label: 'activities',
              children: [
                { 
                  label: 'sports', 
                  content: (
                    <div>
                      <h1>Sports Activities</h1>
                      <p> Information about Sports activities at Troy.</p>
                    </div>
                  )
                },
                { 
                  label: 'arts', 
                  content: (
                    <div>
                      <h1>Arts Activities</h1>
                      <p> Information about Arts activities at Troy.</p>
                    </div>
                  )
                },
              ],
            },
            {
              label: 'awards',
              children: [
                { 
                  label: 'academic', 
                  content: (
                    <div>
                      <h1>Academic Awards</h1>
                      <p> Information about Academic awards at Troy.</p>
                    </div>
                  )
                },
                { 
                  label: 'athletic', 
                  content: (
                    <div>
                      <h1>Athletic Awards</h1>
                      <p> Information about Athletic awards at Troy.</p>
                    </div>
                  )
                },
              ],
            },
          ],
        },
        {
          label: 'etc',
          children: [
            { 
              label: 'misc', 
              content: (
                <div>
                  <h1>Miscellaneous</h1>
                  <p> Miscellaneous information content.</p>
                </div>
              )
            },
          ],
        },
      ];
    
      const renderTree = (nodes) => (
        <div>
          {nodes.map((node) => (
            <Folder key={node.label}>
              {node.children ? (
                <>
                  <FolderLabel onClick={() => toggleCollapse(node.label)}>
                    <FolderIcon src={folder} />
                    {node.label}
                  </FolderLabel>
                  {collapsed[node.label] && (
                    <div style={{ marginLeft: '20px' }}>
                      {renderTree(node.children)}
                    </div>
                  )}
                </>
              ) : (
                <File onClick={() => handleLinkClick(node)}>{node.label}</File>
              )}
            </Folder>
          ))}
        </div>
      );


    return (
        <Container fluid className="home-section" style={container}>
            <Sidebar>{renderTree(directoryStructure)}</Sidebar>
            <Content>
            {selectedContent ? (
              <>{selectedContent.content}</>
            ) : (
              <p>Select an item from the directory</p>
            )}
          </Content>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Bachelor of Arts</strong></p>
          <p>Harvard College:</p>
          <p><strong>Bachelor of Arts</strong></p>
          <p><strong>Concentration:</strong> Computer Science</p>
          <p><strong>Expected Graduation Date:</strong> 5/29/2025</p>
          <p><strong>Social Class:</strong> 2025</p>
          <p><strong>Year in School:</strong> Junior</p>
          <p><strong>House:</strong> Eliot House</p>
          <p><strong>Secondary Field(s):</strong> Film & Visual Studies</p>
          <p><strong>Language Citation(s):</strong> None</p>
          <p><strong>Admitted Term:</strong> 2021 Fall</p>
          <p><strong>Harvard College Social Class:</strong> 2025</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </Container>
    );
};

export default Education;
