import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import cig_1 from "../../../assets/art/cig_1.jpg";
import cig_2 from "../../../assets/art/cig_2.jpg";
import cig_3 from "../../../assets/art/cig_3.jpg";
import cig_4 from "../../../assets/art/cig_4.jpg";
import crescent_bay from "../../../assets/art/CRESCENT_BAY_3x5.jpg";
import seoul from "../../../assets/art/SEOUL.jpg";
import translation from "../../../assets/art/TRANSLATION_1x1.JPG";
import caligari from "../../../assets/art/CALIGARI_6x8.JPG";
import studio1 from "../../../assets/art/studio1.JPG";
import studio2 from "../../../assets/art/studio2.JPG";
import studio3 from "../../../assets/art/studio3.JPG";
import studio4 from "../../../assets/art/studio4.JPG";
import studio5 from "../../../assets/art/studio5.JPG";

function Art() {
  const [hoveredText, setHoveredText] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleMouseEnter = (text) => {
    setHoveredText(text);
  };

  const handleMouseLeave = () => {
    setHoveredText(null);
  };

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  const intro_blurb = {
    textAlign: 'justify',
    position: 'absolute',
    top: '20vh',
    left: isMobile ? '60vw' : '80vw',
    width: isMobile ? '20vw' : '15vw',
    fontSize: 'small',
  };

  const content = {
    padding: '50px 0 0 0',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'left',
    width: 250,
    height: 250,
  }

  const portrait = {
    maxWidth: '150px'
  }

  const landscape = {
    maxWidth: '200px'
  }

  const tooltipStyle = {
    position: 'absolute',
    top: cursorPos.y + 20, // 20px below the cursor
    left: cursorPos.x + 20, // 20px to the right of the cursor
    backgroundColor: 'white',
    padding: '5px',
    border: '1px solid red',
    zIndex: 1000,
    pointerEvents: 'none',
    fontFamily:'Deja Vu',
    fontSize: 'small',
    color: 'red',
  };

  return (
    <section onMouseMove={handleMouseMove}>
      <div className="grid-background"></div> {/* Grid background */}
      <Container fluid className="art-section">
        <div className="grid-container">
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Cig 01, oil on paper')} onMouseLeave={handleMouseLeave}>
            <img src={cig_1} alt="cig_1"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Cig 02, oil on paper')} onMouseLeave={handleMouseLeave}>
            <img src={cig_2} alt="cig_2"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Cig 03, oil on paper')} onMouseLeave={handleMouseLeave}>
            <img src={cig_3} alt="cig_3"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Cig 04, oil on paper')} onMouseLeave={handleMouseLeave}>
            <img src={cig_4} alt="cig_4"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Seoul, 1x2, oil on canvas')} onMouseLeave={handleMouseLeave}>
            <img src={seoul} alt="seoul" className="art"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Caligari, 6x8, oil on canvs')} onMouseLeave={handleMouseLeave}>
            <img src={caligari} alt="caligari" className="art"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('In Translation, 1x1, oil on canvas')} onMouseLeave={handleMouseLeave}>
            <img src={translation} alt="translation" className="art"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('Crescent Bay, 3x5, oil on canvas')} onMouseLeave={handleMouseLeave}>
            <img src={crescent_bay} alt="crescent_bay" className="art"/>
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
          <div className="grid-item" onMouseEnter={() => handleMouseEnter('')} onMouseLeave={handleMouseLeave}>
            
          </div>
        </div>
        {hoveredText && (
          <div style={tooltipStyle}>
            {hoveredText}
          </div>
        )}
      </Container>
    </section>
  );
}

export default Art;
