import React, { useEffect, useState } from 'react';
import pause from "../assets/audio/pause.svg";
import play from "../assets/audio/play-outline.svg";

interface Props {
  path: string;
  count: Number;
  square: Boolean;
}

const StopMotionGallery: React.FC<Props> = ({path, count, square}) => {
  const imageCount = count;  // Number of images in the folder
  const [hoverIndex, setHoverIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  const button_style = {
    outline: 'solid',
    float: 'right',
    paddingBottom: '5px',
  }

  const footer_style = {
    padding: '20px',
  }

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning) {
      interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % imageCount);
      }, 500);
    }

    return () => clearInterval(interval);
  }, [isRunning, imageCount]);

  const handleClick = () => {
    setIsRunning(!isRunning);
  };
 
  const renderImages = () => {
    const images = [];
    for (let i = 1; i <= imageCount; i++) {
      images.push(
          <img
            key={i}
            src={`/${path}/${i}.jpg`}
            alt={`image-${i}`}
            className={`frame ${i - 1 === currentIndex ? 'visible' : ''}`}
            style={{
              width: '150px',
              objectFit: 'cover',
              height: '150px',
              transform: i - 1 === currentIndex ? 'scale(1)' : 'scale(1)',
              transition: 'transform 0.1s ease-in-out',
              zIndex: i - 1 === currentIndex ? 1 : 0,
              pointerEvents: i - 1 === currentIndex ? 'auto' : 'none', // Disable hover for non-visible images
            }}
            onMouseEnter={(e) => {
              if (!isRunning && i - 1 === currentIndex) {
                e.currentTarget.style.transform = 'scale(1.6)';
                e.currentTarget.style.width = 'fit-content';
                e.currentTarget.style.maxWidth = '400px';
              }
            }}
            onMouseLeave={(e) => {
              if (!isRunning && i - 1 === currentIndex) {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.width = '150px';
                e.currentTarget.style.height = '150px';
              }
            }}
          />
        
      );
    }
    return images;
  };

  return (
    <div id="stopmotion-container">
      {renderImages()}
      <div style={footer_style}>
        <button style={button_style} onClick={() => handleClick()}>
          <img src={isRunning ? pause : play}></img>
        </button>
      </div>
      
    </div>
  );
};

export default StopMotionGallery;
